@import '../../../styles/propertySets.css';

.root {
  width: 16px;
  height: 16px;
  @media (--viewportMedium) {
    width: 20px;
    height: 20px;
  }
}

.marketplaceColorStroke {
  stroke: var(--marketplaceColor);
}
